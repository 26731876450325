$base03:          #fdf6e3 !default;
$base02:          #eee8d5 !default; 
$base01:          #93a1a1 !default; 
$base00:          #839496 !default; 
$base0:           #657b83 !default; 
$base1:           #586e75 !default; 
$base2:           #073642 !default; 
$base3:           #002b36 !default; 
$solar-yellow:    #b58900 !default;
$solar-orange:    #cb4b16 !default;
$solar-red:       #dc322f !default;
$solar-magenta:   #d33682 !default;
$solar-violet:    #6c71c4 !default;
$solar-blue:      #268bd2 !default;
$solar-cyan:      #2aa198 !default;
$solar-green:     #859900 !default;

/* non highlighted code colors */
$pre-bg: $base03 !default;
$pre-border: darken($base02, 5) !default;
$pre-color: $base1 !default;

.highlight {
  table td.code { width: 100%; }
}

.highlight .lineno {
  text-align: right;
  font-size: 15px;
  line-height: 1.45em;
  border-right: 1px solid $liquid-gray !important;
  span { color: $base01 !important; }
  // padding: .8em !important;
}

pre {
  @extend .mono;
  line-height: 1.45em;
  font-size: 15px;
  margin-bottom: 2.1em;
  padding: .8em 1em;
  color: $pre-color;
  overflow: auto;
}

p, li {
  code {
    @extend .mono;
    display: inline-block;
    white-space: no-wrap;
    font-size: .8em;
    line-height: 1.5em;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 0.4em;
    padding: 0 .3em;
    margin: -1px 0;
  }
  pre code { font-size: 1em !important; background: transparent; border: none; }
}

.highlight {
  font-family: $font-mono !important;
  overflow: scroll;
  overflow-y: hidden;
  display: block;
  // padding: .8em;
  overflow-x: auto;
  line-height: 1.45em;

  color: $base1 !important;
  span { color: $base1 !important; }
  span { font-style: normal !important; font-weight: normal !important; }

  .c      { color: $base01 !important; font-style: italic !important; }                     /* Comment */
  .cm     { color: $base01 !important; font-style: italic !important; }                     /* Comment.Multiline */
  .cp     { color: $base01 !important; font-style: italic !important;  }                     /* Comment.Preproc */
  .c1     { color: $base01 !important; font-style: italic !important; }                     /* Comment.Single */
  .cs     { color: $base01 !important; font-weight: bold !important; font-style: italic !important; }   /* Comment.Special */
  .err    { color: $solar-red !important; background: none !important; }                                            /* Error */
  .k      { color: $solar-orange !important; }                       /* Keyword */
  .o      { color: $base1 !important; font-weight: bold !important; }                       /* Operator */
  .p      { color: $base1 !important; }                                             /* Operator */
  .ow     { color: $solar-cyan !important; font-weight: bold !important; }                       /* Operator.Word */
  .gd     { color: $base1 !important; background-color: mix($solar-red, $base03, 25%) !important; display: inline-block; }               /* Generic.Deleted */
  .gd .x  { color: $base1 !important; background-color: mix($solar-red, $base03, 35%) !important; display: inline-block; }               /* Generic.Deleted.Specific */
  .ge     { color: $base1 !important; font-style: italic !important; }                      /* Generic.Emph */
  //.gr     { color: #aa0000 }                                          /* Generic.Error */
  .gh     { color: $base01 !important; }                                          /* Generic.Heading */
  .gi     { color: $base1 !important; background-color: mix($solar-green, $base03, 20%) !important; display: inline-block; }               /* Generic.Inserted */
  .gi .x  { color: $base1 !important; background-color: mix($solar-green, $base03, 40%) !important; display: inline-block; }               /* Generic.Inserted.Specific */
  //.go     { color: #888888 }                                          /* Generic.Output */
  //.gp     { color: #555555 }                                          /* Generic.Prompt */
  .gs     { color: $base1 !important; font-weight: bold !important; }                                       /* Generic.Strong */
  .gu     { color: $solar-violet !important; }                                          /* Generic.Subheading */
  //.gt     { color: #aa0000 }                                          /* Generic.Traceback */
  .kc     { color: $solar-green !important; font-weight: bold !important; }                       /* Keyword.Constant */
  .kd     { color: $solar-blue !important; }                       /* Keyword.Declaration */
  .kp     { color: $solar-orange !important; font-weight: bold !important; }                       /* Keyword.Pseudo */
  .kr     { color: $solar-magenta !important; font-weight: bold !important; }                       /* Keyword.Reserved */
  .kt     { color: $solar-cyan !important; }                       /* Keyword.Type */
  .n      { color: $solar-blue !important; }
  .na     { color: $solar-blue !important; }                                          /* Name.Attribute */
  .nb     { color: $solar-green !important; }                                          /* Name.Builtin */
  .nc     { color: $solar-magenta !important;}                                                   /* Name.Class */
  .no     { color: $solar-yellow !important; }                                          /* Name.Constant */
  //.ni     { color: #800080 }                                          /* Name.Entity */
  .nl     { color: $solar-green !important; }
  .ne     { color: $solar-blue !important; font-weight: bold !important; }                       /* Name.Exception */
  .nf     { color: $solar-blue !important; font-weight: bold !important; }                       /* Name.Function */
  .nn     { color: $solar-yellow !important; }                                          /* Name.Namespace */
  .nt     { color: $solar-blue !important; font-weight: bold !important; }                                          /* Name.Tag */
  .nx     { color: $solar-yellow !important; }
  //.bp     { color: #999999 }                                          /* Name.Builtin.Pseudo */
  //.vc     { color: #008080 }                                          /* Name.Variable.Class */
  .vg     { color: $solar-blue !important; }                                          /* Name.Variable.Global */
  .vi     { color: $solar-blue !important; }                                          /* Name.Variable.Instance */
  .nv     { color: $solar-blue !important; }                                          /* Name.Variable */
  //.w      { color: #bbbbbb }                                          /* Text.Whitespace */
  .mf     { color: $solar-cyan !important; }                                          /* Literal.Number.Float */
  .m      { color: $solar-cyan !important; }                                          /* Literal.Number */
  .mh     { color: $solar-cyan !important; }                                          /* Literal.Number.Hex */
  .mi     { color: $solar-cyan !important; }                                          /* Literal.Number.Integer */
  //.mo     { color: #009999 }                                          /* Literal.Number.Oct */
  .s      { color: $solar-cyan !important; }                                             /* Literal.String */
  //.sb     { color: #d14 }                                             /* Literal.String.Backtick */
  //.sc     { color: #d14 }                                             /* Literal.String.Char */
  .sd     { color: $solar-cyan !important; }                                             /* Literal.String.Doc */
  .s2     { color: $solar-cyan !important; }                                             /* Literal.String.Double */
  .se     { color: $solar-red !important; }                                             /* Literal.String.Escape */
  //.sh     { color: #d14 }                                             /* Literal.String.Heredoc */
  .si     { color: $solar-blue !important; }                                             /* Literal.String.Interpol */
  //.sx     { color: #d14 }                                             /* Literal.String.Other */
  .sr     { color: $solar-cyan !important; }                                          /* Literal.String.Regex */
  .s1     { color: $solar-cyan !important; }                                             /* Literal.String.Single */
  //.ss     { color: #990073 }                                          /* Literal.String.Symbol */
  //.il     { color: #009999 }                                          /* Literal.Number.Integer.Long */
  div { .gd, .gd .x, .gi, .gi .x { display: inline-block; width: 100%; }}
}

.highlight {
  pre { background: none; padding: 0,8em; margin-bottom: 0; }
  margin-bottom: 1.8em;
  overflow-y: hidden;
  overflow-x: auto;
}

$solar-scroll-bg: rgba(#000, .15);
$solar-scroll-thumb: rgba(#000, .15);

pre, .highlight {
  &::-webkit-scrollbar {  height: .3em; background: $solar-scroll-bg; }
  &::-webkit-scrollbar-thumb:horizontal { background: $solar-scroll-thumb; }
}

.highlighter-rouge > .highlight {
  background: $color-background;
  padding: 0;
  border: 0;
  margin-bottom: 1.5em;
  pre { margin-bottom: 0; }
  
  table {
      background: $color-background;
      border: 0px;
      border-spacing: 0;
      margin-top: 0px;
      margin-bottom: 0px;
      td {
        text-align: left;
      }
    }
}
