footer {
	padding: 15px 0;
	text-align: center;
	font-size: 0.8em;

	.copyright {
		display: inline-block;
	}

	.separator {
		margin-left: 10px;
		display: inline-block;
	}
}
